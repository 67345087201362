var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {},
    [
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-center" } },
        [
          _vm.options.showList
            ? _c("data-source-list", { on: { showCard: _vm.showCard } })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-top" } },
        [
          _vm.options.showAdd
            ? _c("data-source-add", {
                attrs: { data: _vm.options.data },
                on: { showCard: _vm.showCard },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-top" } },
        [
          _vm.options.showEdit
            ? _c("data-source-edit", {
                attrs: { data: _vm.options.data },
                on: { showCard: _vm.showCard },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "el-zoom-in-bottom" } },
        [
          _vm.options.showDetail
            ? _c("data-source-detail", {
                attrs: { data: _vm.options.data },
                on: { showCard: _vm.showCard },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }